.modal-dialog.modal-fullsize {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.modal-content.modal-fullsize {
    height: auto;
    min-height: 100%;
    border-radius: 0;
}
.small-padding {
    padding: 0 0 0 0;    
    font-size:1rem;
}

/* 검색창 대여일시 및 반납일시 선택 달력창 스타일*/
.btn-close {
    background-color: #b6b6b6;
    color: #fff;
}

#vue_search_app{
    font-size:1em;
    border-radius:10px;
}

.search_date{
    flex:0 0 80px;
}

.search_durtime {
    flex: 0 0 48px;
    font-size: .8em;
}

.search_title {
    color: #0b3f36;
    font-size: .95em;
    white-space: nowrap;    
    font-weight: 400;
    padding-right:1em;
}

.search_btn {
    font-weight: 400;
}

.search_content {
    font-weight: 600;
}

#et_year, #et_weekday, #st_year, #st_weekday, #st_time, #et_time {
    font-size: .75em;
    line-height:1em;
}

#st_date, #et_date {
    font-size: 1.8em;
    line-height: 1em;
}

#vue_search_app .ttlheadmark {
    width:3px;
    height:10px;
}
//체크박스.
.btn-check { //체크박스 안보이게 변경
    position: absolute;
    clip: rect(0,0,0,0);
    pointer-events: none;
}

.btn-check + .btn-check-label, .btn-check:checked + .btn-check-label, .btn-check-label.active {
    color: #fff;
    background-color: #cccccc;
    border-color: #fff;
    font-weight: 700;
}

.btn-check:active + .btn-check-label, .btn-check:checked + .btn-check-label, .btn-check-label.active {
    color: #fff;
    background-color: #535353;
}

.btn-check + .btn-check-all, .btn-check:checked + .btn-check-all, .btn-check-all.active {
    color: #fff;
    background-color: #cccccc;
    border-color: #fff;
}

.btn-check:active + .btn-check-all, .btn-check:checked + .btn-check-all, .btn-check-all.active {
    color: #fff;
    background-color: #535353;
}

@media (min-width: 768px) {
    .search_date,.search_ins{
        flex: 0 0 200px;
    }

    .search_cartype{
        flex:1 1 auto;
    }

    .search_durtime {
        flex: 0 0 90px;
        font-size: 1em;
    }

    .search_title {
        font-size: 1em;
        flex: 0 0 auto;
    }

    #et_year, #et_weekday, #st_year, #st_weekday, #st_time, #et_time, #st_date, #et_date {
        font-size: 1.2em;
    }
}

@media (min-width: 992px) {
    .search_condition{
        flex:1 1 auto;
    }
    .search_btn {
        flex: 0 0 200px;
    }

    .search_date, .search_ins {
        flex: 0 0 200px;
    }
    .search_cartype{
        flex:1 1 auto;
    }
    .search_durtime {
        flex: 0 0 80px;
        font-size: 1.2em;
    }

    .search_title {
        font-size: 1em;
        flex: 0 0 95px;
    }

    #et_year, #et_weekday, #st_year, #st_weekday, #st_time, #et_time, #st_date, #et_date {
        font-size: 1.2em;
    }
}

@media (min-width: 1200px) {
    .search_date, .search_ins{
        flex: 0 0 260px;
    }
    .search_cartype{
        flex:1 1 auto;
    }
    .search_durtime {
        flex: 0 0 100px;
        font-size: 1.2em;
    }

    #et_year, #et_weekday, #st_year, #st_weekday, #st_time, #et_time, #st_date, #et_date {
        font-size: 1.5em;
    }
}

@media (max-width: 300px) {
    .minisizenone{
        display:none;
    }
    }